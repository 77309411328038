import React, { Component } from 'react';
import GoogleMapsComponent from './GoogleMapsComponent';

export default class Map extends Component {
    constructor(props) {
        super(props);
        this.state = {
            center: { 
                lat: 0, 
                lng: 0, 
                zoom: 5 
            },
            translateStrings: [],
            rodo: '',
            pins: [],
            formValidate: [],
            pinUrl: '',
            status: false,
            activeBox: 999999,
        }
    }

    async componentDidMount() {
        await this.getData();
    }

    getData() {
        let url = `${window.location.protocol}//${window.location.hostname}`;
        // let url ='https://bkfcarwash.eu'; 
        fetch(`${url}/wp-json/wp/v2/contact-map-data`)
            .then(resp => resp.json())
            .then(resp => {
                this.setState({ 
                    center: { 
                        lat: parseFloat(resp.default_settings.lat), 
                        lng: parseFloat(resp.default_settings.lng), 
                        zoom: parseFloat(resp.default_settings.zoom) 
                    },
                    translateStrings: resp.translate_text.strings,
                    rodo: resp.translate_text.rodo,
                    pins: resp.pins,
                    formValidate: resp.form,
                    pinUrl: resp.default_settings.pin_url,
                    status: true,
                });
            })
    }

    changePosition(center) {
        this.setState({
            center: {
                lat: parseFloat(center.lat), 
                lng: parseFloat(center.lng), 
                zoom: parseFloat(this.state.center.zoom)
            }
        })
    }

    render() {
        return (
            <div id="map">
                { this.state.status && 
                    <GoogleMapsComponent
                        center={this.state.center}
                        translateStrings={this.state.translateStrings}
                        rodo={this.state.rodo}
                        pins={this.state.pins}
                        formValidate={this.state.formValidate}
                        pinUrl={this.state.pinUrl}
                        changeActiveBox={(val) => this.setState({activeBox: val})}
                        activeBox={this.state.activeBox}
                        changePosition={(center) => this.changePosition(center)}
                    />
                }
            </div>
        );
    }
    
}

