import React from "react";
import './styles.scss';

const Input = (props) =>  (
    <div className={props.size == "full" ? (props.error ? "text-input full-size error" : "text-input full-size") : (props.error ? "text-input error" : "text-input")}>
        <span className={props.required ? "title required" : "title"}>{ props.title }</span>
        {props.size == "full" &&
            <textarea type="text" className="input-val textarea" onChange={(e) => props.changeVal(e.target.value)} value={props.val} />
        }
        {props.size != "full" &&
            <input type="text" className="input-val" onChange={(e) => props.changeVal(e.target.value)} value={props.val} />
        }
    </div>
)

export default Input;