import React, { Component } from 'react';                    
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from 'react-google-maps';
import mapStyles from './mapStyles.json';
import Popup from './Popup/Popup';


const MapWithAMarker = withScriptjs(withGoogleMap(props =>
    <GoogleMap
        zoom={props.center.zoom}
        defaultCenter={{ lat: props.center.lat, lng: props.center.lng }}
        center={{ lat: props.center.lat, lng: props.center.lng }}
        defaultOptions={ { styles: mapStyles, disableDefaultUI: true, zoomControl: false, streetViewControl: false, mapTypeControl: false } }
    >
        {props.markers && props.markers.map((marker, index) => (
            <Marker 
                position={{
                    lat: parseFloat(marker.coordinates_group.lat),
                    lng: parseFloat(marker.coordinates_group.lng)
                }}
                icon={{
                    url: props.markerIcon,
                    
                }}
                onClick={() => { props.changeActiveBox(index); props.changePosition(marker.coordinates_group) }}
            >
            {props.activeBox == index &&
                <Popup 
                    changeActiveBox={(val) => props.changeActiveBox(val)}
                    translate={props.translate}
                    marker={marker}
                    checkRequiredValidate={props.checkRequiredValidate}
                    rodo={props.rodo}
                />
            }
            </Marker>
        ))}
    </GoogleMap>
));

class GoogleMapsComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }
    render() {
        return (
            <MapWithAMarker
                googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyD8voO99fkLyc_LjKKGaF-LA2aPxVhZJKM&v=3.exp&libraries=geometry,drawing,places"
                loadingElement={<div style={{ height: `100%` }} />}
                containerElement={<div style={{ height: `600px` }} />}
                mapElement={<div style={{ height: `100%` }} />}
                center={this.props.center}
                markerIcon={this.props.pinUrl}
                markers={this.props.pins}
                translate={this.props.translateStrings}
                checkRequiredValidate={this.props.formValidate}
                changePosition={(center) => this.props.changePosition(center)}
                changeActiveBox={(val) => this.props.changeActiveBox(val)}
                activeBox={this.props.activeBox}
                rodo={this.props.rodo}
             />
            )
    }
}

export default GoogleMapsComponent