import React, { useState, useEffect } from "react";
import './styles.scss';
import Input from './../Input/Input';

const Popup = (props) =>  {
    let [ load, setLoad ] = useState(false);
    let [ classHeight, setClassHeight ] = useState('');
    let [ emailSent, setEmailSent ] = useState(false);
    let [ inputs, setInputs ] = useState([
        {
            name: 'name_surname',
            title: '',
            value: '',
            required: true,
            error: false,
        },  
        {
            name: 'email',
            title: '',
            value: '',
            required: true,
            error: false,
        },  
        {
            name: 'phone',
            title: '',
            value: '',
            required: true,
            error: false,
        },  
        {
            name: 'message',
            title: '',
            value: '',
            required: true,
            error: false,
        },  
        {
            name: 'rodo',
            title: '',
            value: '',
            required: true,
            error: false,
        }
    ]);

    useEffect(() => {
        if(!load) {
            checkInputs();
            checkHeight();
            setLoad(true);
        }
      },[load])

    const checkInputs = () => {
        let validate = props.checkRequiredValidate;
        let titles = props.translate;
        let copyInputs = [...inputs];
        let checkRequire = false;
        let title = '';
        inputs.map((input, i) => {
            if(input.name != 'rodo') {
                checkRequire = validate[input.name] == "required" ? true : false;
                title = titles[input.name];
            }

            if(input.name == 'rodo') {
                copyInputs[i].title = props.rodo;
                copyInputs[i].value = false;
            }else{
                copyInputs[i].required = checkRequire;
                copyInputs[i].title = title;
            }
        })
        setInputs(copyInputs);
    }

    const updateInputs = (newValue, index) => {
        let copyInputs = [...inputs];
        copyInputs[index].value = newValue;
        setInputs(copyInputs);
    }

    const validateInputs = () => {
        let statusErrors = 0;
        let copyInputs = [...inputs];
        inputs.map((input, index) => {

            if(input.required) {
                if(input.value.length <= 2) {
                    copyInputs[index].error = true;
                    statusErrors++;
                }else{
                    copyInputs[index].error = false;
                }
                if(input.name == 'email') {
                    if(input.value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)){
                        copyInputs[index].error = false;
                    }else{
                        copyInputs[index].error = true;
                        statusErrors++;
                    }
                }
                if(input.name == 'rodo') {
                    if(input.value) {
                        copyInputs[index].error = false;
                    }else{
                        copyInputs[index].error = true;
                        statusErrors++;
                    }
                }
            }
        })
        setInputs(copyInputs);
        if(statusErrors == 0) {
            sendMessage();
        }
    }

    const sendMessage = () => {
        let url = `${window.location.protocol}//${window.location.hostname}`;
        fetch(`${url}/wp-json/wp/v2/contact-map-send-email/?name=${inputs[0].value}&email=${inputs[1].value}&phone=${inputs[2].value}&message=${inputs[3].value}&to=${props.marker.emails}`)
            .then(resp => resp.json())
            .then(resp => {
                setEmailSent(true)
            })
    }

    const checkHeight = () => {
        setTimeout(function(){
            let popup = document.getElementsByClassName('popup-box');
            let windowHeight = window.innerHeight;
            for(let i = 0; i<popup.length;i++) {
                let popupHeight = popup[i].scrollHeight;
                if(popupHeight > windowHeight) {
                    setClassHeight('scrollHeightBox');
                }
            }
        }, 100);
    }
    return (
        <div className="popup-wrapper-map">
            <div className={(emailSent ? "popup-box small-box " : "popup-box ") + classHeight}>
                <a onClick={() => props.changeActiveBox(999999)} className="close-popup">{props.translate.close}</a>
                {!emailSent &&
                    <div className="info">
                        <h2>{props.marker.country}</h2>
                        {props.marker.link_group.link &&
                            <span>{props.translate.editorial}
                                <a href={props.marker.link_group.link} target="_blank"> {props.marker.link_group.link_text}</a>
                            </span>
                        }
                        {!props.marker.link_group.link &&
                            <span className="withoutwww">{props.translate.editorial_without_www}</span>
                        }
                    </div>
                }
                {load && !emailSent &&
                <div className="form">
                    <div className="box-wrapper">
                        <div className="box">
                            <div className="items">
                                {inputs.map((input, index) => input.name != "message" && input.name != "rodo" && (
                                <Input 
                                    title={input.title}
                                    name={input.name}
                                    required={input.required}
                                    val={input.value}
                                    error={input.error}
                                    changeVal={(val) => input.name == "phone" ? (/^[0-9\b]+$/.test(val) ? updateInputs(val, index) : null) : updateInputs(val, index)}
                                />
                                ))}
                            </div>
                            <div className="items">
                                {inputs.map((input, index) => input.name == "message" && (
                                <Input 
                                    size="full"
                                    title={input.title}
                                    required={input.required}
                                    val={input.value}
                                    error={input.error}
                                    changeVal={(val) => updateInputs(val, index)}
                                />
                                ))}
                            </div>
                        </div>
                        <div className="box">
                            {inputs.map((input, index) => input.name == "rodo" && (
                                <label for="rodo" className="checkbox-rodo">
                                    <div class="check-box-wrapper">
                                        <input id="rodo" className={input.error ? "value-checkbox error" : "value-checkbox"} type="checkbox" value={input.value} onClick={() => updateInputs(!input.value, index)} />
                                    </div>
                                    <p className="hint" dangerouslySetInnerHTML={{ __html: input.title }} />
                                </label>
                            ))}
                        </div>
                    </div>
                    <div className="btn-wrapper">
                        <span className="fields-required">{props.translate.fields_required}</span>
                        <a className="btn red-btn" onClick={() => validateInputs()}>{props.translate.send}</a>
                    </div>
                </div>
                }
                {emailSent &&
                    <div className="email-sent">
                        <small>{props.translate.done_header}</small>
                        <strong>{props.translate.done_header2}</strong>
                        <p>{props.translate.done_text}</p>
                    </div>
                }
            </div>
        </div>
    )
}

export default Popup;